import { Link } from 'gatsby';
import React from 'react';

const Footer = (props) => (
  <footer className="footer-container">
    {props.smartfeedCompanyData &&
      props.smartfeedCompanyData.CompanyName &&
      props.smartfeedCompanyData.CompanyName !== 'DefaultCompany' && (
        <div className="footer-register-wrap">
          {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
            <div className="footer-register-container container">
              <div className="vf-careers-site-spacing-cta">
                <article aria-label="Subscribe for job alerts">
                  <div className="ctacard soft">
                    <div className="ctacard__body d-flex justify-content-center">
                      <div className="col-md-10 col-lg-6 text-center">
                        <h4 className="ctacard__body__title">
                          <span>Can't find a job role to suit you?</span>
                        </h4>
                        <p className="ctacard__body__summary">
                          Get job alerts straight to your inbox and be the first
                          to see our new vacancies.
                        </p>
                        <Link
                          to={
                            !props.siteConfig.companyCareersSite
                              ? `${
                                  props.useGroupVacsLink === true
                                    ? '/group-job-alerts/'
                                    : `/job-alerts/`
                                }${props.apiKey}`
                              : props.useGroupVacsLink === true
                              ? '/group-job-alerts/'
                              : `/job-alerts/`
                          }
                          className="btn--action"
                        >
                          Subscribe for job alerts
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )}
        </div>
      )}
    <div className="footer-attribution-wrap">
      <div className="footer-attribution-container container">
        <div className="left text-center text-lg-left">
          {!props.siteConfig.companyCareersSite && (
            <span>
              Copyright © {new Date().getFullYear()} |{' '}
              <a
                rel="noopener"
                target="noopener noreferrer"
                href={`https://www.vacancyfiller.com/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                  props.smartfeedCompanyData &&
                  props.smartfeedCompanyData.CompanyName
                    ? props.smartfeedCompanyData.CompanyName
                    : props.apiKey
                    ? props.apiKey
                    : 'noidavailable'
                }`}
              >
                Powered Vacancy Filler
              </a>
            </span>
          )}
          <span className="accessibility-selectors">
            <ul>
              <li className="fontSize_Selection">
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                  onClick={() => props.setFontSize('normal')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                  onClick={() => props.setFontSize('medium')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                  onClick={() => props.setFontSize('large')}
                >
                  <span className="fal fa-font"></span>
                </button>
              </li>
              <li className="contrast_Selection">
                {props.colourContrast ? (
                  <button
                    className="contrast_trigger contrast_trigger_light"
                    onClick={() => props.setColourContrast(false)}
                  >
                    <span className="fas fa-adjust"></span>
                  </button>
                ) : (
                  <button
                    className="contrast_trigger contrast_trigger_dark"
                    onClick={() => props.setColourContrast(true)}
                  >
                    <span className="fad fa-adjust"></span>
                  </button>
                )}
              </li>
            </ul>
          </span>
        </div>
        <div className="center" style={{ width: '100%' }}>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '8.0pt',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            The information that you are about to submit is electronically held
            and processed by Staff 86 Limited.
          </p>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '8.0pt',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            By entering your personal information, you are permitting Staff 86
            Limited to access and use the information for recruitment purposes
            or as they state. Should this company be part of a larger group, the
            other companies within this group will have access to your personal
            information. Your personal information may be shared with our
            clients or with external partners and agencies involved in order to
            comply with relevant laws and regulations whilst delivering our
            services. They will only have access to your information if it is
            needed to fulfil a request of the company, such as an external
            audit, or deliver the services provided by Staff 86 Limited. These
            providers are obliged to keep your details secure.
          </p>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '8.0pt',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            Your data will be saved periodically before you reach the end of the
            application form. This is to help you if you want to complete the
            form over a number of visits and to limit data loss as a result of a
            technical issue such as an accidental browser closure or a loss of
            internet connection.
          </p>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '8.0pt',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            Staff 86 Limited will store your details for a retention period of 1
            year set by Staff 86 Limited and your personal information will be
            deleted automatically once the data retention period is reached.
          </p>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '8.0pt',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            Should you wish to remove your details prior to automatic deletion
            or for any other queries about how your information is handled,
            please contact Staff 86 Limited directly.
          </p>
          <p
            style={{
              marginRight: '0cm',
              marginLeft: '0cm',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              marginTop: '0cm',
              marginBottom: '0px',
              paddingBottom: '10px',
              lineHeight: '107%',
              textAlign: 'center',
            }}
          >
            If you have issues with this, you may contact the Staff 86 Limited
            team on 01244 6333 86 or email jobs@staff86.com.&nbsp;
          </p>
        </div>
        <div className="right text-center text-lg-right">
          {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
            <>
              <a
                href={props.appGatewayURL.ApplicantGateway}
                target="_blank"
                rel="noreferrer"
              >
                Applicant Gateway
              </a>
              {!props.siteConfig.companyCareersSite && <span> | </span>}
            </>
          )}
          {!props.siteConfig.companyCareersSite && (
            <>
              <a
                href={`https://www.vacancyfiller.com/privacy-policy/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                  props.apiKey ? props.apiKey : 'noidavailable'
                }`}
                rel="noopener"
                target="noopener noreferrer"
              >
                Vacancy Filler Privacy Policy
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
